import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "menu-management-wrapper"
  }, [_c("div", {
    staticClass: "operator-row"
  }, [_c("el-button", {
    attrs: {
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.addMenu("0");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("menu.add")))])], 1), _vm._v(" "), _vm.showTable ? _c("el-table", {
    attrs: {
      data: _vm.tableData,
      "row-key": "ID"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: _vm.$t("menu.id"),
      "min-width": "100",
      prop: "ID"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("menu.name"),
      "min-width": "160",
      prop: "name"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("menu.path"),
      "min-width": "160",
      prop: "path"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("menu.visibility"),
      "min-width": "100",
      prop: "hidden"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.hidden ? _vm.$t("menu.hide") : _vm.$t("menu.show")))])];
      }
    }], null, false, 1861233642)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("menu.parent"),
      "min-width": "120",
      prop: "parentId"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("menu.sort"),
      "min-width": "70",
      prop: "sort"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("menu.file_path"),
      "min-width": "360",
      prop: "component"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("menu.label"),
      "min-width": "120",
      prop: "authorityName"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.meta.title))])];
      }
    }], null, false, 2200158133)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("menu.icon"),
      "min-width": "140",
      prop: "authorityName"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          class: "el-icon-".concat(scope.row.meta.icon)
        }), _vm._v(" "), _c("span", [_vm._v(_vm._s(scope.row.meta.icon))])];
      }
    }], null, false, 3721365252)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: _vm.$t("menu.operation"),
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "small",
            type: "primary",
            icon: "el-icon-plus",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.addMenu(scope.row.ID);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          attrs: {
            size: "small",
            type: "primary",
            icon: "el-icon-edit",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.editMenu(scope.row.ID);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          attrs: {
            size: "small",
            type: "danger",
            icon: "el-icon-delete",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.deleteMenu(scope.row.ID);
            }
          }
        })];
      }
    }], null, false, 3256750313)
  })], 1) : _vm._e(), _vm._v(" "), _c("el-dialog", {
    staticClass: "menu-editor",
    attrs: {
      "before-close": _vm.handleClose,
      title: _vm.dialogTitle,
      visible: _vm.dialogFormVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "menuForm",
    attrs: {
      inline: true,
      model: _vm.form,
      rules: _vm.rules,
      "label-position": "top",
      size: "small"
    }
  }, [_c("div", {
    staticClass: "item-group"
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("menu.name"),
      prop: "path"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      placeholder: _vm.$t("menu.name_placeholder")
    },
    on: {
      change: _vm.changeName
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "path"
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block"
    },
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("menu.path")) + "\n          "), _c("el-checkbox", {
    staticStyle: {
      float: "right",
      "margin-left": "20px"
    },
    model: {
      value: _vm.checkFlag,
      callback: function callback($$v) {
        _vm.checkFlag = $$v;
      },
      expression: "checkFlag"
    }
  }, [_vm._v(_vm._s(_vm.$t("menu.add_parameter")))])], 1), _vm._v(" "), _c("el-input", {
    attrs: {
      disabled: !_vm.checkFlag,
      autocomplete: "off",
      placeholder: _vm.$t("menu.path_placeholder")
    },
    model: {
      value: _vm.form.path,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "path", $$v);
      },
      expression: "form.path"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "item-group"
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("menu.visibility")
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: _vm.$t("menu.visibility")
    },
    model: {
      value: _vm.form.hidden,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "hidden", $$v);
      },
      expression: "form.hidden"
    }
  }, [_c("el-option", {
    attrs: {
      value: false,
      label: _vm.$t("common.no")
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      value: true,
      label: _vm.$t("common.yes")
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("menu.parent")
    }
  }, [_c("el-cascader", {
    attrs: {
      disabled: !this.isEdit,
      options: _vm.menuOption,
      props: {
        checkStrictly: true,
        label: "title",
        value: "ID",
        disabled: "disabled",
        emitPath: false
      },
      "show-all-levels": false,
      filterable: ""
    },
    model: {
      value: _vm.form.parentId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "parentId", $$v);
      },
      expression: "form.parentId"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "item-group"
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("menu.file_path"),
      prop: "component"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.form.component,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "component", $$v);
      },
      expression: "form.component"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("menu.label"),
      prop: "meta.title"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.form.meta.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form.meta, "title", $$v);
      },
      expression: "form.meta.title"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "item-group"
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("menu.icon"),
      prop: "meta.icon"
    }
  }, [_c("icon", {
    attrs: {
      meta: _vm.form.meta
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_vm._v("el-icon-")])], 2)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("menu.sort"),
      prop: "sort"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.form.sort,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "sort", _vm._n($$v));
      },
      expression: "form.sort"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "item-group"
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("menu.keep_alive"),
      prop: "meta.keepAlive"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: _vm.$t("menu.keep_alive_placeholder")
    },
    model: {
      value: _vm.form.meta.keepAlive,
      callback: function callback($$v) {
        _vm.$set(_vm.form.meta, "keepAlive", $$v);
      },
      expression: "form.meta.keepAlive"
    }
  }, [_c("el-option", {
    attrs: {
      value: false,
      label: _vm.$t("common.no")
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      value: true,
      label: _vm.$t("common.yes")
    }
  })], 1)], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "ext-param-pannel"
  }, [_c("div", {
    staticClass: "operator-row"
  }, [_c("el-button", {
    attrs: {
      size: "small",
      type: "primary",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        return _vm.addParameter(_vm.form);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("menu.add_menu_parameter")))]), _vm._v(" "), _c("div", {
    staticClass: "warning"
  }, [_c("i", {
    staticClass: "el-icon-info"
  }), _vm._v(" " + _vm._s(_vm.$t("menu.add_menu_parameter_tip")))])], 1), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.form.parameters,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "type",
      label: _vm.$t("menu.parameter_type"),
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-select", {
          attrs: {
            placeholder: _vm.$t("menu.parameter_key_tip")
          },
          model: {
            value: scope.row.type,
            callback: function callback($$v) {
              _vm.$set(scope.row, "type", $$v);
            },
            expression: "scope.row.type"
          }
        }, [_c("el-option", {
          key: "query",
          attrs: {
            value: "query",
            label: "query"
          }
        }), _vm._v(" "), _c("el-option", {
          key: "params",
          attrs: {
            value: "params",
            label: "params"
          }
        })], 1)];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "key",
      label: _vm.$t("menu.parameter_key"),
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_c("el-input", {
          model: {
            value: scope.row.key,
            callback: function callback($$v) {
              _vm.$set(scope.row, "key", $$v);
            },
            expression: "scope.row.key"
          }
        })], 1)];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "value",
      label: _vm.$t("menu.parameter_value")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_c("el-input", {
          model: {
            value: scope.row.value,
            callback: function callback($$v) {
              _vm.$set(scope.row, "value", $$v);
            },
            expression: "scope.row.value"
          }
        })], 1)];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "80"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [_c("el-button", {
          attrs: {
            type: "danger",
            size: "small",
            icon: "el-icon-delete",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.deleteParameter(_vm.form.parameters, scope.$index);
            }
          }
        })], 1)];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.enterDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.save")))]), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };